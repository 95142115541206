:root {
    color-scheme: light dark;
    --brand--primary: rgba(248,158,36,1);
    --brand--seondary: rgba(246,67,59,1);
    --brand--danger: rgba(255,39,91,1);
    --brand--peace: rgba(152,63,255,1);
    --brand--accent: rgba(33,201,255,1);
    --brand--success: rgba(41,246,212,1);
    --brand--font--white: rgba(255,255,255,1);
    --brand--font--link: rgba(194,193,230,1);
    --bg--primary: rgba(35,33,87,1);
    --bg--secondary: rgba(55,53,112,1);
    --bg--dark: rgba(25,24,64,1);
}