@use '../abstracts' as *;

.minh--100vh {
    min-height: 100vh;
}

.maxh--100 {
    max-height: 100%;
}

.mx--auto {
    margin-left: auto;
    margin-right: auto;
}

.maxw--100 {
    max-width: 100%;
}

.login--heading {
    font-size: 1.5rem;
    color: var(--brand--font--white);
    margin: 0;
    line-height: normal;
    font-weight: 500;

    @include respond(tablet-md) {
        font-size: 1.875rem;
    }
}

.login--text {
    font-size: .875rem;
    color: var(--brand--font--white);
    font-weight: 400;
    margin-bottom: 0;
    margin-top: .85rem;

    @include respond(tablet-md) {
        font-size: 1rem;
    }
}

.login--wrapperbox {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}

.buttonlogin {
    margin-top: 2.5rem;

    button,
    a {
        width: 100%;
    }
}

.login__textfiled {
    width: 100%;
    max-width: 600px;
}

.login__innerarea {
    padding: 0px 1rem;
}

.brandlogo {

    svg,
    img {
        max-width: 100px;
        margin: 0 auto;
        height: 110px;

        @include respond(tablet-lg) {
            height: auto;
        }
    }
}

.overflow--hidden {
    overflow: hidden;
}

.h-inherit {
    height: inherit;
}

.text--center {
    text-align: center;
}

.text--lg--start {
    @include respond(tablet-lg) {
        text-align: start;
    }
}

.ms--1 {
    margin-left: .5rem;
}

.max--760 {
    max-width: 760px;
}

.tag-t-0 {
    >* {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.text--end {
    text-align: end;
}

.dateoutlined {
    label {
        color: var(--brand--font--white) !important;

        &.Mui-focused {
            color: var(--brand--font--white);

            fieldset {
                border-width: 1px;
            }
        }

        &.Mui-error {
            color: #fff !important;
        }
    }

    .MuiOutlinedInput-root {
        &.Mui-error {
            fieldset {
                border-color: rgba(255, 255, 255, 0.23);
            }
        }

        &:hover {
            fieldset {
                border-color: var(--brand--primary) !important;
                border-width: 1px;
            }
        }
    }
}

.align--items--center {
    align-items: center;
}

body {
    -webkit-tap-highlight-color: transparent;
}

.avatorpic--16by9 {
    >img {
        aspect-ratio: 16/9;
        width: auto;
        object-position: left;
        height: auto;
        object-fit: contain;
    }
}

.text--initial {
    text-transform: initial !important;
}

.text--center {
    text-align: center;
}

.showdetail__descBox>div>div:nth-child(3)>div {
    background-color: #ffff !important;
}

.cs__range {
    z-index: 1;

    @include respond(tablet-md) {
        // position: absolute;
        // right: 0;
        // top: -10px;
        max-width: 500px;
    }
}

.ps-md-4 {
    @include respond(tablet-md) {
        padding-left: 20px;
    }
}

.MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
}


.cursor__pointer .MuiDataGrid-row {
    cursor: pointer;
}

.shw-dtl-dt-rng {
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 1.5rem;
    display: inline-block;
    background: rgb(255, 255, 255, 0.03);
    padding: 0.6em 1em;
    border-radius: 4px;
    // text-align: right;
}

.w-100 {
    width: 100%;

    &.w-md-auto {
        @include respond(tablet-md) {
            width: auto;
        }
    }
}

.max-w-180 {
    max-width: 200px !important;
}

.text--dark {
    color: var(--bg--dark);
}

.creator__side {
    width: 80%;
}

.position--relative {
    position: relative;
}

.table__actionbuttons {
    margin: 20px 0px 10px;
    padding: 0px 15px;
    text-align: end;

    @media only screen and (min-width: 900px) {
        margin: 0;
        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 1;
        padding: 0;
    }

    @include respond(tablet-lg) {
        top: 70px;
        right: 70px;
    }
}

.MuiLinearProgress-root {
    >span {
        background: var(--brand--success);
    }
}

.red--whiteIcon {
    svg {
        color: #fff;
    }
}

.dashboard__content__cards {
    display: grid;
    grid-gap: 1.25rem;
    padding: 0 15px;

    @include respond(tablet-md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond(desktop-lg) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.new__color__cards {
    margin-bottom: 1.875rem;
    background-color: #fff;
    transition: all .5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: 1.75rem;
    box-shadow: 0px 5px 5px 0px rgb(82 63 105 / 5%);
    padding: 35px 30px 45px 30px;

    &.warning {
        background-color: #ffa755;
    }

    &.success {
        background-color: var(--brand--success);
    }

    &.purple {
        background-color: rgb(180, 141, 211);
    }

    &.primary {
        background-color: var(--brand--font--link);
    }

    &::after {
        position: absolute;
        content: "";
        display: inline-block;
        height: 120px;
        width: 120px;
        transform: rotate(40deg);
        top: -35px;
        right: -35px;
        border-radius: 1.75rem;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#26ffffff', endColorstr='#00ffffff', GradientType=1);
    }

    &--grid {
        display: grid;
        grid-template-columns: 60px 1fr;
        grid-gap: 1rem;

        .icon {
            height: 60px;
            width: 60px;
            min-width: 60px;
            line-height: 54px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.4);
            text-align: center;
        }

        p {
            font-size: 38px;
            margin-bottom: 0px;
            color: #fff;
            font-weight: 600;
        }

        span {
            font-size: 18px;
            line-height: 1.5;
            display: block;
            color: #fff;
            font-weight: 400;
        }
    }
}


.MuiDataGrid-iconSeparator {
    visibility: hidden;
}

.td__mailinfo {
    position: relative;
    padding-left: 1.5rem;
    margin-top: 0.4rem;
    color: var(--color-lightgrey);
}

.td__mailinfo .bi {
    position: absolute;
    font-size: 1rem;
    left: 0;
    line-height: 1;
    top: 2px;
    color: var(--color-primary);
}


.true--verified::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f00c";
    /* position: absolute;*/
    /*left: 0px;*/
    font-size: 15px;
    /*top: 50%;*/
    /*transform: translateY(-50%)*/
    ;
    /*color: #5c4fc7;*/
    color: #008000;
}

.false--verified::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f00d";
    /*position: absolute;*/
    /*left: 0px;*/
    font-size: 16px;
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    color: #da195f;
}

.edit_btn {
    color: #02406c;
    padding: 10px 10px;
    border: 1px solid;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    height: 44px;
}

.edit_btn:hover {
    color: #fff;
    background: #02406c;
}

.btn_group {
    align-items: center;
}


.sticky-header {
    position: sticky;
    left: 0;
    z-index: 1;
}

// .MuiDataGrid-columnHeader:first-child
.sticky-col .MuiDataGrid-root .MuiDataGrid-cell:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    // background-color: #e0ece7;
    background-color: #fff;
}

.sticky-col .MuiDataGrid-colCellWrapper:first-child {
    position: sticky !important;
    left: 0;
    z-index: 1;
    background-color: #c58da1;
    // transform: none !important;
    // background-color: #e4e;
    // background-color: #cbdbcb;
    // background-color: #fff;
    // border-right: 1px solid #C4C4C4;
    // margin-top: "0 !important";
}

.sticky-col .MuiDataGrid-colCell:first-child {
    position: sticky !important;
    left: 0;
    z-index: 1;
    background-color: #c58da1;
    // transform: none !important;
    // background-color: #e4e;
    // background-color: #cbdbcb;
    // background-color: #fff;
    // border-right: 1px solid #C4C4C4;
    // margin-top: "0 !important";
}

.sticky-col .MuiDataGrid-columnHeader:first-child {
    position: sticky !important;
    left: 0;
    z-index: 1;
    // background-color: #c58da1;
    // transform: none !important;
    // background-color: #e4e;
    // background-color: #cbdbcb;
    // background-color: #fff;
    // border-right: 1px solid #C4C4C4;
    // margin-top: "0 !important";
}

.sticky--columns .MuiDataGrid-row {
    .MuiDataGrid-cell {
        &:nth-child(2) {
            position: sticky;
            left: 50px;
            background: #fff;
            z-index: 1;
        }

        &:nth-child(1) {
            position: sticky;
            left: 0px;
            background: #fff;
            z-index: 1;
        }
    }

    &:hover,
    &.Mui-selected {
        .MuiDataGrid-cell {

            &:nth-child(2),
            &:nth-child(1) {
                background-color: rgb(246 246 246) !important;
            }
        }
    }
}

.sidebar-wrapper .MuiListItem-root a {
    font-weight: bold;
    text-transform: initial;
    letter-spacing: normal;
    font-family: initial;
}


.MuiDataGrid-columnHeadersInner {
    transform: translate(0px, 0px) translateZ(0);
}

.MuiDataGrid-colCell:first-child .MuiDataGrid-colCellTitle {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
}

.MuiDataGrid-root .MuiDataGrid-colCell:first-child .MuiDataGrid-colCellTitle {
    text-transform: none;
}

.MuiDataGrid-root .MuiDataGrid-colCell:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
}

.timeline__wrapper {
    position: relative;
    margin-top: .25rem;

    .timeline__item {
        display: flex;
        align-items: flex-start;
        position: relative;

        +.timeline__item {
            margin-top: 1.7rem;
        }

        &__badge {
            flex-shrink: 0;
            background: #faa50145;
            --size: 1rem;
            width: var(--size);
            height: var(--size);
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            position: relative;
            margin-top: 5px;
            padding: 3px !important;
            border: 0px solid #fff;
            margin-left: -0.35rem;

            span {
                background-color: #faa501;
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }
        }

        &__content {
            flex-grow: 1;
            padding-left: .5rem;

            &--title {
                font-size: 1.015rem;
                color: var(--color-text);
                font-weight: 500;
                margin-bottom: 0rem;
                line-height: 1.4;
            }

            &--exp {
                font-size: .875rem;
                color: var(--color-lightgrey);
                font-weight: 400;
                margin-bottom: 0rem;
                line-height: 1.4;
            }

            &--location {
                font-size: .875rem;
                color: var(--color-text);
                font-weight: 400;
                margin-bottom: 0rem;
                line-height: 1.4;
                position: relative;
                padding-left: 1.65rem;

                svg {
                    position: absolute;
                    left: -2px;
                }
            }
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: 0px;
        width: 3px;
        top: 10px;
        bottom: 0;
        // background-color: #eff2f5;
        background-color: #eff2f5;
    }
}


.custom-sidebar-icon {
    color: rgb(43 116 85) !important;
}

.custom-sidebar-text {
    color: rgb(106, 160, 137) !important;
}

.custom-sidebar-header-text {
    color: #198754 !important;
    font-size: 16px !important;
    font-weight: 900 !important;
}

.custom-sidebar-color {
    background: #eef4ee !important
}

.signout-btn {
    background: rgb(120 175 131) !important;
}

.custom-switch .MuiSwitch-colorPrimary {
    color: red !important
}

.fix-width-text {
    word-wrap: break-word;
    /* break words when they exceed the width */
    word-break: break-all;
    /* break words at any character when they exceed the width */
}

.fix-width-employer-name {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
}


.fix-width-data {
    position: relative;
    padding-left: 20px;

    svg {
        position: absolute;
        left: 0;
        top: 0;
    }

    span {
        white-space: normal;
        text-align: left !important;
        display: inline-block;
        word-break: break-all;
    }
}


.read-more-css {
    color: red;
}

.read-less-css {
    color: rgb(47 90 210);
}

/* style the scrollbar */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
}

/* style the scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: rgb(206, 227, 206);
    border-radius: 25px;
}

/* hover effect for scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}


.dropdown-button {
    appearance: none;
    background-color: #e7e7e7;
    border: none;
    color: #000;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.dropdown-button:hover {
    background-color: #ddd;
}