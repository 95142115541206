/* Change the white to any color */
input:-webkit-autofill.form__auth--text,
input:-webkit-autofill:hover.form__auth--text, 
input:-webkit-autofill:focus.form__auth--text, 
input:-webkit-autofill:active.form__auth--text{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
    background-color: #fff !important;
}
.skills_list {
    margin-top: 1.875rem;
    position: relative;
}
.skills_list ul {
    list-style-type: none;
    padding-left: 0px;
    display: flex;
}
.skills_list ul li {
    font-size: .875rem;
    font-weight: 500;
    line-height: 1;
    background: #f9f9f9;
    padding: .3rem 0.875rem;
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 5%);
    flex-shrink: 0;
    margin-right: 15px;
}
.skills_list ul li button {
    margin-left: auto;
}
.skills_list ul li button {
   color:  var(--color-warning);
    background: #bf1f190d;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}
button#add-new-skill {
    border-radius: 50%;
    padding: 0;
    text-align: center;
    --size: 40px;
    width: var(--size);
    height: var(--size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* table#employerdt tr td:nth-child(1), table#employerdt tr th:nth-child(1) {
    max-width: 200px;
    word-break: break-word;
} */

.dataTables_paginate .paginate_button:not(.disabled):hover span {
    color: var(--color-primary);
}

.auth-logo {
    max-width: 5rem;
}

body .form__auth__control > .input--error {
	border-color: var(--color-warning);
}

body .form__auth__control > .input--error + label.form__auth--label {
	color: var(--color-warning);
}

.line--clamp-height {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: auto;
    word-break: break-word;
    text-overflow: unset;
    line-height: normal;
}
table#alljobdt tr td:nth-child(1), table#alljobdt tr th:nth-child(1) {
    max-width: 120px;
    min-width: 120px;
    word-wrap: break-word;
}
/* #alljobdt {
    max-width: 100% !important;
}
#ongoingjobdt {
    max-width: 100% !important;
}
#completedjobdt {
    max-width: 100% !important;
} */

table#ongoingjobdt tr td:nth-child(1), table#ongoingjobdt tr th:nth-child(1) {
    max-width: 120px;
    min-width: 120px;
    word-wrap: break-word;

}table#completedjobdt tr td:nth-child(1), table#completedjobdt tr th:nth-child(1) {
    max-width: 120px;
    min-width: 120px;
    word-wrap: break-word;
}

.all__categories__list .age__radio__tab {
    margin: 0;
    width: 100%;
    padding: 1.25rem 1.5rem 1.015rem 2rem;
    justify-content: space-between;
}
.all__categories__list .radio__tabs label span {
    border-style: dashed;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-color: #c2e1c4;
}
.all__categories__list .radio__tabs label input:checked ~ span {
    background-color: #f9f9f9;
}
.all__categories__list .radio__tabs label input:checked + small {
    color: var(--color-primary);
}
.all__categories__list .age__radio__tab + .age__radio__tab span {
    border-top: 0px;
}
.all__categories__list .radio__tabs label input:checked ~ i {
    color: var(--color-primary);
}
.th__heading {
    background: #f0f9f0;
    font-size: 1.05rem;
    font-weight: 600;
    padding: 1.25rem 1.5rem 1rem;
    line-height: 1;
}
.maxw-1000 {
    max-width: 1000px;
}
.dropdown--option > button {
    background: transparent;
    border: 0;
    padding: 0;
    height: auto;
    border-radius: 0;
    line-height: 0;
    transform: rotate(90deg);
    font-size: 0;
}
.dropdown--option > button svg {
    width: 14px;
    fill: #299d30;
}
.all__categories__list .radio__tabs label i {
    position: absolute;
    left: 15px;
    top: 23px;
}
.dropdown--option > button:hover, .dropdown--option > button:focus, .dropdown--option > button:active {
    color: var(--color-primary);
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
}
.dropdown--option .dropdown-menu {
    padding: 0rem;
}
.dropdown--option .dropdown-menu li a {
    padding: .75rem 1.25rem;
    font-size: .875rem;
}
.dropdown--option .dropdown-menu li a:hover {
    background: transparent;
    color: #d5322a;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    margin-left: 8%;
}

.btn--statuscheck {
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    font-weight: 500;
    margin-left: 0 !important;
    padding: 6px 18px !important;
}
.btn--statuscheck:hover {
    background: #fff3e9;
    color: #f39444;
    border-color: #fff3e9;
}

.btn--block {
    background: white;
    border: 2px solid #f2f3f5;
    color: #637381;
    padding: 6px 18px;
}

.btn--unblock {
    background: #09b95429;
    border: 1px solid #baefd0;
    color: #0a8e49;
    padding: 6px 18px;
}

.btn--anchor {
    display: inline-block;
    background: #f2f3f5;
    padding: 6px 18px;
    text-align: center;
    border-radius: 5px;
    color: #637381;
    font-weight: 500;
    text-decoration: none;
    margin-right: 8px !important;
}
.btn--anchor:hover {
    background: #e7efff;
    color: #0a58ca;
}

.text--primary {
    color: var(--color-primary);
}
.welcome__minimal__box--title {
    line-height: 1.6;
    font-size: 1.75rem;
    margin-top: 0.5rem;
}
span.dtr-data {
    display: inline-block;
    max-width: max-content;
    word-break: break-all;
}
.manage-users-tabs {
    margin-top: 0;
}
.page__main__container {
    padding-top: 3rem;
}
.dataTables_paginate span {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.manage-users-tabs .nav.nav-pills {
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    overflow: auto;
}
/* .dataTables_wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
} */
/* .dataTables_wrapper thead tr th, .dataTables_wrapper tbody tr td {
    width: auto !important;
    word-break: break-all;
} */
.btn--anchor ~ * {
    margin-left: 0 !important;
    margin-right: 8px !important;
}
.btn--anchor, .btn--anchor ~ * {
    margin-bottom: 8px !important;
}
.dataTables_wrapper thead tr th {
    padding: 1rem !important;
}
.dataTables_wrapper thead tr th.sorting {
    padding-left: 2.25rem !important;
}
/* #employerdt tbody tr,#employerdt thead tr {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    width: 100%;
} */
.dataTables_wrapper {
    overflow-x: auto;
}
#employerdt td, #employerdt th {
    min-width: 80px;
    max-width: 90px;
    word-break: break-word;
   } 
   #employerdt tr td:last-child, #employerdt th:last-child {
    min-width: 160px;
    max-width: 160px;
   }
   
 #skilldt td, #skilldt th {
    min-width: 80px;
    max-width: 90px;
    word-break: break-word;
   } 
   #skilldt td:last-child, #skilldt th:last-child {
    min-width: 160px;
    max-width: 160px;
   }

.loader--initial {
    display: inline-flex;
    background: white;
    position: fixed;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(50%,50%);
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 3px 12px rgb(231 231 231);
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
}
.loader--initial i {
    font-size: 40px;
    color: #0b8e49;
}

@media(min-width: 992px) {
    /* #employerdt tbody tr, #employerdt thead tr {
        grid-template-columns: repeat(4,1fr);
    } */
    .dataTables_wrapper tbody tr td {
        padding-left: 1rem !important;
        /* word-break: break-word; */
    }
}

@media(min-width: 1200px) {
    .page__main__container {
        padding-top: 4rem;
    }
    .manage-users-tabs .nav.nav-pills {
        padding-bottom: 0rem;
    }
    .dataTables_paginate span {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
    }
    /* #employerdt tbody tr, #employerdt thead tr {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    } */
}
/* .table.dataTable {
    max-width: 100% !important;
}
.dataTables_wrapper thead tr th, .dataTables_wrapper tbody tr td {
    box-sizing: border-box;
} */

.uploaded{
    color: var(--color-primary);
}

.not-uploaded{
    color: red;
}